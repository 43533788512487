import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { question7, arrowRight, errorIcon, tick } from "../assets";
import { useState } from "react";

export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const NameCard = ({ handleCardDisplay, typeFormData, setTypeFormData, setValidDetails }) => {
  const [errorFlagName, setErrorFlagName] = useState(false);

  const handleName = (event) => {
    setErrorFlagName(false);
    setValidDetails(false);
    setTypeFormData({ ...typeFormData, name: event.target.value });
  };
  return (
    <div className="question-box single-question-card">
      <div className="question-right">
        <Image source={question7} alt="question7" />
      </div>
      <div className="question-left">
        <div className="question-cards">
          <div className="question-no">
            <div className="question-arrow">
              {" "}
              6 <Image source={arrowRight} alt="arrow" />
            </div>
          </div>
          <div className="question-info">
            <h4>
              What is your name?
              {errorFlagName && <small>This question is required.*</small>}
            </h4>
            <p>Let's get on a first name basis!</p>

            <div className="question-input">
              <input placeholder="Type your answer here..." onChange={handleName} value={typeFormData.name} />
            </div>

            <div>
              <button
                onClick={() => {
                  if (typeFormData.name === "") {
                    setErrorFlagName(true);
                  } else {
                    handleCardDisplay(7);
                  }
                }}
                className="question-button"
                type="submit"
              >
                OK <Image source={tick} alt="tick" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameCard;
