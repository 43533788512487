import React, { useState } from "react";
import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { arrowRight, question4, tick, errorIcon } from "../assets";
import { isValidDate } from "../utils/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/de";
import dayjs from "dayjs";
// import DateFNSUtils from "@material-ui/lab/AdapterDateFns";

export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const DateCard = ({ handleCardDisplay, typeFormData, setTypeFormData }) => {
  // const [errorFlagDate, setErrorFlagDate] = useState(false);
  const [validDate, setValidDate] = useState(false);
  const [selectedDate, setSelectedDate] = React.useState(
    `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`
  );

  const handleDateChange = (event) => {
    setValidDate(false);
    setSelectedDate(`${event.$M + 1}/${event.$D}/${event.$y}`);
  };

  const handleDateSubmit = () => {
    // setValidDate(false);
    // if (!isValidDate(typeFormData.date)) {
    //   setValidDate(true);
    // } else {
    //   handleCardDisplay(5);
    // }
    setTypeFormData({ ...typeFormData, date: selectedDate });
    handleCardDisplay(5);
  };

  return (
    <div className="question-box single-question-card card-image-right">
      <div className="question-right">
        <Image source={question4} alt="question4" />
      </div>
      <div className="question-left">
        <div className="question-cards">
          <div className="question-no">
            <div className="question-arrow">
              {" "}
              4 <Image source={arrowRight} alt="arrow" />
            </div>
          </div>
          <div className="question-info">
            <h4>
              When is the event?
              {/* {errorFlagDate && <small>This question is required.*</small>} */}
            </h4>
            <p>Next week or next year? Don't worry. We've got you covered!</p>
            <div className="question-input input-flex">
              <div className="date-fields">
                <label>Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-us">
                  <DatePicker
                    onChange={handleDateChange}
                    label="MM/DD/YYYY"
                    defaultValue={dayjs(typeFormData.date)}
                    minDate={dayjs(new Date())}
                  />
                </LocalizationProvider>
              </div>
            </div>
            {validDate && (
              <div className="error-box">
                <Image source={errorIcon} alt="error" /> <strong>Oops!</strong> Please enter valid Date
              </div>
            )}
            <div>
              <button onClick={handleDateSubmit} className="question-button" type="submit">
                OK <Image source={tick} alt="tick" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateCard;
