import {
  businessEvent,
  dateNight,
  music,
  aeroPlane,
  ladies,
  calender,
  wedding,
  everyDay,
  hot,
  fitRight,
  girl,
  trendy,
  other,
} from "../assets";

export const EventOptions = [
  { label: "A", title: "Business Event", icon: businessEvent },
  { label: "B", title: "Date Night", icon: dateNight },
  { label: "C", title: "Music Event", icon: music },
  { label: "D", title: "Vacation / Travel", icon: aeroPlane },
  { label: "E", title: "Ladies Event", icon: ladies },
  {
    label: "F",
    title: "Special Event (cocktail party, gala, etc.)",
    icon: calender,
  },
  { label: "G", title: "Wedding Event", icon: wedding },
  { label: "H", title: "Everyday", icon: everyDay },
];

export const FeelOptions = [
  { label: "A", title: "Smoking Hot", icon: hot },
  { label: "B", title: "Fit Right In", icon: fitRight },
  { label: "C", title: "Polished & Professional", icon: girl },
  { label: "D", title: "Trendy & Fashionable", icon: trendy },
  { label: "E", title: "Other", icon: other },
];

export const isValidEmail = (email) => {
  const checkEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return checkEmailValidation.test(email);
};

export const isValidPhone = (phone) => {
  const checkPhoneValidation = /^(?:\+?1)?[-.\s]?\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

  return checkPhoneValidation.test(phone);
};

export const isValidDate = (date) => {
  const checkDateValidation = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

  return checkDateValidation.test(date);
};

export const UPLOAD_FILE_TYPE = {
  VIDEO: "video",
  IMAGE: "image",
};

export const FILE_UPLOAD_SIZE = {
  IMAGE_SIZE: 5000000,
  VIDEO_SIZE: 10000000,
};
export const SEASONS = ["Summer", "Winter", "Spring", "Fall"];

export const COLOR_OPTIONS = [
  {
    id: 1,
    name: "Black",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_black.png",
  },
  {
    id: 2,
    name: "White",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_white.png",
  },
  {
    id: 3,
    name: "Gray",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_gray.png",
  },
  {
    id: 4,
    name: "Navy Blue",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_navy_blue.png",
  },
  {
    id: 5,
    name: "Light Blue",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_light_blue.png",
  },
  {
    id: 6,
    name: "Green",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_green.png",
  },
  {
    id: 7,
    name: "Natural",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_natural.png",
  },
  {
    id: 8,
    name: "Brown",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_brown.png",
  },
  {
    id: 9,
    name: "Red",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_red.png",
  },
  {
    id: 10,
    name: "Yellow",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_yellow.png",
  },
  {
    id: 11,
    name: "Pink",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_pink.png",
  },
  {
    id: 12,
    name: "Orange",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_orange.png",
  },
  {
    id: 13,
    name: "Purple",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/color_purple.png",
  },
  {
    id: 14,
    name: "Animal Pattern",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/pattern_animal.png",
  },
  {
    id: 15,
    name: "Paisely Pattern",
    url: "https://d2pn5mqzo6inf0.cloudfront.net/uploads/stylePreferences/pattern_paisely.png",
  },
];
export const defaultValue = {
  category: "",
  brand: "",
  color: "",
  season: "",
};
export const defaultFilterValue = {
  category: [],
  brand: "",
  color: "",
  season: "",
};
