import { Dialog } from "@mui/material";
import React from "react";
import { trashBin } from "../assets";
import confirmModalStyles from "../styles/confirmationModal.css";

export const links = () => [{ rel: "stylesheet", href: confirmModalStyles }];

const ConfirmationModal = ({ open, onClose, handleDeleteCloset }) => {
  return (
    <Dialog open={open} onClose={onClose} className="closet-confirm-box">
      <div className="closet-confirm-box-inner">
      <figure>
      <img src={trashBin} alt="trash" />
      </figure>
        <h2>Are you sure you want to delete?</h2>
        <div className="closet-confirm-box-btn">
        <button onClick={onClose}>Cancel</button>
        <button onClick={handleDeleteCloset}>Confirm</button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmationModal;
