import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { errorIcon, question8, arrowRight, tick } from "../assets";
import { useState } from "react";
import { isValidEmail } from "../utils/constants";
export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const EmailCard = ({ handleCardDisplay, typeFormData, setTypeFormData, setValidDetails }) => {
  const [errorFlagEmail, setErrorFlagEmail] = useState(false);
  const [validFlag, setValidFlag] = useState(false);

  const handleEmail = (event) => {
    setErrorFlagEmail(false);
    setValidDetails(false);
    setValidFlag(false);
    setTypeFormData({ ...typeFormData, email: event.target.value });
  };
  return (
    <div className="question-box single-question-card card-image-right">
      <div className="question-right">
        <Image source={question8} alt="question8" />
      </div>
      <div className="question-left">
        <div className="question-cards">
          <div className="question-no">
            <div className="question-arrow">
              {" "}
              7 <Image source={arrowRight} alt="arrow" />
            </div>
          </div>
          <div className="question-info">
            <h4>
              What is your email address?
              {errorFlagEmail && <small>This question is required.*</small>}
            </h4>
            <p>Don't worry, we'll only be using your email for style session notifications.</p>
            <div className="question-input">
              <input placeholder="name@example.com" onChange={handleEmail} value={typeFormData.email} />
            </div>
            {validFlag && (
              <div className="error-box">
                <Image source={errorIcon} alt="error" /> <strong>Oops!</strong> Please enter valid Email
              </div>
            )}

            <div>
              <button
                onClick={() => {
                  if (typeFormData.email === "") {
                    setErrorFlagEmail(true);
                  } else if (!isValidEmail(typeFormData.email)) {
                    setValidFlag(true);
                  } else {
                    handleCardDisplay(8);
                  }
                }}
                className="question-button"
                type="submit"
              >
                OK <Image source={tick} alt="tick" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailCard;
