import React from "react";
import ClosetCard from "../cards/ClosetCard";
import { defaultFilterValue } from "../utils/constants";

const CardContainer = ({ data, handleClose, filterValue }) => {
  return data?.length > 0 ? (
    <div className="closet-card-main">
      {data?.map((el) => (
        <React.Fragment key={el?.id}>
          {el?.type && el?.url && (
            <div className="closet-card-col">
              <ClosetCard data={el} handleClose={handleClose} />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  ) : (
    <div className="closet-card-no-data">
      {filterValue === defaultFilterValue ? (
        <p>
          Click on the + to start adding items you own to your virtual closet.
        </p>
      ) : (
        <p>No closet found!</p>
      )}
    </div>
  );
};

export default CardContainer;
