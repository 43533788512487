import React, { useState } from "react";
import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { arrowRight, question3, tick } from "../assets";

export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const CityCard = ({ typeFormData, setTypeFormData, handleCardDisplay, setValidDetails }) => {
  const [errorFlag, setErrorFlag] = useState(false);

  const handleCity = (event) => {
    setValidDetails(false);
    setErrorFlag(false);
    setTypeFormData({ ...typeFormData, city: event.target.value });
  };

  return (
    <div className="question-box single-question-card">
      <div className="question-right">
        <Image source={question3} alt="question3" />
      </div>

      <div className="question-left">
        <div className="question-cards">
          <div className="question-no">
            <div className="question-arrow">
              {" "}
              3 <Image source={arrowRight} alt="arrow" />
            </div>
          </div>
          <div className="question-info">
            <h4>
              What city is this event in?
              {errorFlag && <small> This question is required.* </small>}
            </h4>
            <p>We want to make sure you're styled for the weather, city vibes, and more!</p>
            <div className="question-input">
              <input placeholder="Type your answer here..." onChange={handleCity} value={typeFormData.city} />
            </div>
            <div>
              <button
                onClick={() => {
                  if (typeFormData.city === "") {
                    setErrorFlag(true);
                  } else {
                    handleCardDisplay(4);
                  }
                }}
                className="question-button"
                type="submit"
              >
                OK <Image source={tick} alt="tick" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityCard;
