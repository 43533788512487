import axios from "axios";

export const createS3Path = ({ email, category, season, color, brand, type }) => {
  let path = `${email}/${type}/${category}`;
  // if (brand) {
  //   path += `/${brand}`;
  // }
  // if (season) {
  //   path += `/${season}`;
  // }
  // if (color) {
  //   path += `/${color}`;
  // }
  return path;
};

export const checkUploadFileSize = (files, size) => {
  files = Array.from(files);
  return files.reduce((ac, file) => {
    if (file.size <= size) {
      ac.push(file);
    }
    return ac;
  }, []);
};

export const getClosets = (userId, a) => {
  let params = "";
  Object.entries(a).forEach(([key, value]) => {
    if (key === "category") {
      value.forEach((element) => {
        params += `${params ? "&" : "/?"}${key}[]=${element}`;
      });
    } else if (value) {
      params += `${params ? "&" : "/?"}${key}[]=${value}`;
    }
  });
  return axios.get(`${process.env.REACT_APP_BASE_URL}/closet/${userId}${params}`);
};
