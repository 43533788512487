import { Box, Chip, MenuItem, OutlinedInput, Select } from "@mui/material";
import React, { useState } from "react";
import { COLOR_OPTIONS, SEASONS } from "../utils/constants";
import closetHeaderStyles from "../styles/closetHeader.css";
import { filter } from "../assets";
import { cross, selectIcon, filterReset } from "../assets";

export const links = () => [{ rel: "stylesheet", href: closetHeaderStyles }];

const ClosetHeader = ({
  categoriesAndBrand,
  value,
  handleValueFilter,
  setFilterValue,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  return (
    <div className="closet-header">
      {/* <div className="closet-header-text">
        <h1>Closet</h1>
        <p>Closet description here</p>
      </div> */}
      <div className="closet-header-filter-btn">
        <button onClick={handleShowFilter}>
          <>
            {Object.values(value)?.filter((el) => el.length > 0)?.length > 0 ? (
              <span>
                {
                  Object.values(value)?.filter((el) => el.length !== 0 && el)?.length
                }
              </span>
            ) : (
              <>
                <img src={filter} alt="filter" />
              </>
            )}
          </>
          Filters{" "}
        </button>
        {Object.values(value)?.filter((el) => el.length > 0)?.length > 0 && (
          <button
            onClick={() =>
              setFilterValue({
                ...value,
                category: [],
                brand: "",
                color: "",
                season: "",
              })
            }
          >
            <img src={filterReset} alt="selectIcon" /> Reset
          </button>
        )}
      </div>
      {showFilter && (
        <div className="closet-header-filter">
          <div className="closet-form-field">
            <label>Category</label>
            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              multiple
              displayEmpty
              fullWidth
              name="category"
              value={value.category}
              onChange={handleValueFilter}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <MenuItem>Select Category</MenuItem>;
                }
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                );
              }}
              IconComponent={() => {
                return value?.category?.length > 0 ? (
                  <span
                    onClick={() => setFilterValue({ ...value, category: [] })}
                    size="small"
                    className="closet-select-icon closet-cross-icon"
                  >
                    <img src={cross} alt="close" />
                  </span>
                ) : (
                  <span className="closet-select-icon">
                    <img src={selectIcon} alt="selectIcon" />
                  </span>
                );
              }}
            >
              <MenuItem disabled value="">
                Select Category
              </MenuItem>
              {categoriesAndBrand?.category?.map((el) => (
                <MenuItem value={el?.name} key={el?.id}>
                  {el?.name}
                </MenuItem>
              ))}
            </Select>
            {/* <Select displayEmpty name="category" value={value.category} onChange={handleValueFilter} fullWidth>
              <MenuItem value={""}>Select Category</MenuItem>
              {categoriesAndBrand?.category?.map((el) => (
                <MenuItem value={el?.name} key={el?.id}>
                  {el?.name}
                </MenuItem>
              ))}
            </Select> */}
          </div>
          <div className="closet-form-field">
            <label>Season</label>

            <Select
              name="season"
              value={value.season}
              onChange={handleValueFilter}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select Season</MenuItem>
              {SEASONS?.map((el) => (
                <MenuItem value={el} key={el}>
                  {el}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="closet-form-field">
            <label>Color</label>

            <Select
              name="color"
              className="closet-color-select"
              value={value.color}
              onChange={handleValueFilter}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select Color</MenuItem>

              {COLOR_OPTIONS?.map((el) => (
                <MenuItem value={el.name} key={el.id}>
                  <div className="closet-form-color">
                    <span>
                      <img src={el.url} alt={el.name} />
                    </span>
                    {el.name}
                  </div>
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="closet-form-field">
            <label>Brand</label>
            <Select
              name="brand"
              value={value.brand}
              onChange={handleValueFilter}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Select Brand</MenuItem>
              {categoriesAndBrand?.brands?.map(
                (el, index) =>
                  el && (
                    <MenuItem value={el} key={index}>
                      {el}
                    </MenuItem>
                  )
              )}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClosetHeader;
