import typeformStyles from "../styles/typeform.css";
import { questionVideo, videoPoster } from "../assets";
export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const HomeCard = ({ handleCardDisplay }) => {
  return (
    <div className="question-box">
      <div className="question-left">
        <h2>How It Works</h2>
        <h3>Step 1: Help Us Get To Know You</h3>
        <p>Complete this questionnaire so that we can get to know you & your style preferences.</p>

        <h3>Step 2: Connect with a Stylist</h3>
        <p>
          Once you've completed the questionnaire you'll be connected with a stylist. If you booked a chat session your
          stylist will text you in the next 5 minutes. If you booked a video chat you'll be directed the stylists'
          calendar to book a video call for today or any other day/time that works for you!
        </p>
        <h3>Step 3: Get Styled</h3>
        <p>
          A personal stylist will work with your existing wardrobe to curate a complete outfit with items you already
          own & suggest new outfit options. You can get help instantly or schedule a time that works for you.
        </p>
        <button onClick={() => handleCardDisplay(1)} className="question-button button-home" type="submit">
          Start
        </button>
      </div>
      <div className="question-right">
        <video preload="auto" autoPlay loop muted playsInline poster={videoPoster}>
          <source type="video/mp4" src={questionVideo}></source>
        </video>
      </div>
    </div>
    //   </div>
  );
};

export default HomeCard;
