import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { question9, arrowRight, errorIcon } from "../assets";
import ReactInputMask from "react-input-mask";
export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const PhoneCard = ({
  setTypeFormData,
  typeFormData,
  handleSubmit,
  errorPhone,
  setErrorPhone,
  validPhoneFlag,
  setValidPhoneFlag,
  validDetails,
  setValidDetails,
}) => {
  const handlePhone = (event) => {
    setValidDetails(false);
    setErrorPhone(false);
    setValidPhoneFlag(false);
    setTypeFormData({ ...typeFormData, phone: event.target.value });
  };

  return (
    <div className="question-box single-question-card">
      <div className="question-right">
        <Image source={question9} alt="question9" />
      </div>
      <div className="question-left">
        <div className="question-cards">
          <div className="question-no">
            <div className="question-arrow">
              {" "}
              8 <Image source={arrowRight} alt="arrow" />
            </div>
          </div>
          <div className="question-info">
            <h4>
              What is your phone number?
              {errorPhone && <small>This question is required.*</small>}
            </h4>
            <p>Don't worry, we'll only be using your phone number for style session notifications.</p>
            <div className="question-input">
              {/* <input placeholder="(201) 555-0123" onChange={handlePhone}  value={typeFormData.phone}/> */}
              <ReactInputMask
                mask="(999) 999-9999"
                placeholder="(201) 555-0123"
                onChange={handlePhone}
                value={typeFormData.phone}
              />
            </div>
            {validPhoneFlag && (
              <div className="error-box">
                <Image source={errorIcon} alt="error" /> <strong>Oops!</strong> Please enter valid Phone Number
              </div>
            )}
            {validDetails && (
              <div className="error-box">
                <Image source={errorIcon} alt="error" /> <strong>Oops!</strong> Please provide all the information
                requested in previous questions.
              </div>
            )}
            <div>
              <button onClick={handleSubmit} className="question-button" type="submit">
                Submit{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneCard;
