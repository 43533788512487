import { CircularProgress, Input, List, ListItem, MenuItem, Select } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { COLOR_OPTIONS, SEASONS, UPLOAD_FILE_TYPE } from "../utils/constants";
import fileUploadStyles from "../styles/fileUpload.css";
import { imageUpload, videoUpload, cross, Fall, Spring, Summer, Winter } from "../assets";

export const links = () => [{ rel: "stylesheet", href: fileUploadStyles }];

const ImageSrcForSeason = (type) => {
  switch (type) {
    case SEASONS[0]:
      return Summer;
    case SEASONS[1]:
      return Winter;
    case SEASONS[2]:
      return Spring;
    default:
      return Fall;
  }
};

const FileUpload = ({
  value,
  handleValueSet,
  handleOnChange,
  handleSubmitForm,
  buttonDisabled,
  categories,
  imageFiles,
  videoFiles,
  handleRemoveFile,
  handleFileDragOver,
  handleFileDrop,
  videoRef,
  imageRef,
  loading,
}) => {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmitForm();
      }}
    >
      <div className="closet-dialog-inner">
      {loading &&(
        <div className="closet-dialog-loader">
        <CircularProgress size={30} />
</div>
      )}
     
        <div className="closet-upload">
          <div className="closet-upload-col">
            <h5>Upload Images</h5>
            <div className="closet-upload-box">
              <figure>
                <img src={imageUpload} alt="upload" />
                <p>Drag and drop or click to upload</p>
              </figure>

              <input
                name="image"
                type="file"
                placeholder="image file only"
                accept="image/*"
                multiple
                onChange={(event) => handleOnChange(event, UPLOAD_FILE_TYPE.IMAGE)}
                onDragOver={handleFileDragOver}
                onDrop={(event) => handleFileDrop(event, UPLOAD_FILE_TYPE.IMAGE)}
                ref={imageRef}
              />
            </div>
            <div className="closet-upload-list">
              {imageFiles?.map((el, index) => (
                <div className="closet-upload-list-name" key={index}>
                  <span> {el?.name}</span>
                  <img onClick={() => handleRemoveFile(index, UPLOAD_FILE_TYPE.IMAGE)} src={cross} alt="cross" />
                </div>
              ))}
            </div>
          </div>
          <div className="closet-upload-col">
            <h5>Upload Videos</h5>
            <div className="closet-upload-box">
              <figure>
                <img src={videoUpload} alt="upload" />
                <p>Drag and drop or click to upload</p>
              </figure>
              <input
                name="video"
                type="file"
                placeholder="video file only"
                accept="video/*"
                multiple
                onChange={(event) => handleOnChange(event, UPLOAD_FILE_TYPE.VIDEO)}
                onDragOver={handleFileDragOver}
                onDrop={(event) => handleFileDrop(event, UPLOAD_FILE_TYPE.VIDEO)}
                ref={videoRef}
              />
            </div>
            <div className="closet-upload-list">
              {videoFiles?.map((el, index) => (
                <div className="closet-upload-list-name" key={index}>
                  <span> {el?.name}</span>
                  <img onClick={() => handleRemoveFile(index, UPLOAD_FILE_TYPE.VIDEO)} src={cross} alt="cross" />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="closet-row">
          <div className="closet-row-col">
            <div className="closet-form-field">
              <label>*Category</label>
              <Select
                name="category"
                value={value.category}
                label="Select Category"
                onChange={(event) => handleValueSet(event)}
                placeholder="Select Category"
                fullWidth
                displayEmpty
              >
                <MenuItem value={""}>Select Category</MenuItem>
                {categories?.map((el) => (
                  <MenuItem value={el?.name} key={el?.id}>
                    {el?.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div className="closet-row-col">
            <div className="closet-form-field">
              <label>Brand</label>
              <Input name="brand" type="text" value={value.brand} onChange={handleValueSet} />
            </div>
          </div>
          <div className="closet-row-col">
            <div className="closet-form-field">
              <label>Color</label>
              <div className="closet-form-color">
                {COLOR_OPTIONS?.map((el) => (
                  <Tooltip title={el.name}>
                    <span
                      key={el.id}
                      className={el.name === value.color ? "closet-form-color-active" : ""}
                      onClick={(event) => handleValueSet(event, "color", el.name)}
                    >
                      <img src={el.url} alt={el.name} />
                    </span>
                  </Tooltip>
                ))}
              </div>
            </div>
          </div>
          <div className="closet-row-col">
            <div className="closet-form-field">
              <label>Season</label>
              <List className="closet-form-list">
                {SEASONS.map((el) => (
                  <ListItem
                    name="season"
                    key={el}
                    className={`${el === value.season ? "closet-form-list-active" : ""}`}
                    onClick={(event) => handleValueSet(event, "season", el)}
                  >
                    <figure>
                      <img src={ImageSrcForSeason(el)} alt={el} />
                    </figure>
                    {el}
                  </ListItem>
                ))}
              </List>
            </div>
          </div>
        </div>
        {/* add cursor pointer */}
        <div className="closet-form-button">
          <button disabled={buttonDisabled} type="submit">
            Submit {buttonDisabled && loading && <CircularProgress size={15} />}
          </button>
        </div>
      </div>
    </form>
  );
};

export default FileUpload;
