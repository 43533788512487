import axios from "axios";

class FileUploadService {
  constructor() {
    this.signUrl = [];
    this.keys = [];
    this.output = [];
  }
  async uploadFile({ files, path }) {
    if (!files.length) return [];
    const signedUrlArray = files?.map((file) => {
      const payload = {
        fileName: file.name,
        path,
      };
      return this.getSignedUrl(payload);
    });

    const resolvedSignedUrlArray = await this.handlePromises(signedUrlArray);

    const s3UploadArray = resolvedSignedUrlArray?.map((el, index) => {
      if (el?.status === "fulfilled") {
        this.signUrl.push(el?.value?.data?.key);
        return this.s3Upload(el?.value?.data?.uploadUrl, files[index]);
      }
    });
    const s3UploadArrayResolvedArray = await this.handlePromises(s3UploadArray);
    s3UploadArrayResolvedArray?.forEach((el, index) => {
      if (el?.status === "fulfilled") {
        this.output.push(this.signUrl[index]);
      }
    });
    return this.output;
  }

  getSignedUrl(payload) {
    return axios.post(`${process.env.REACT_APP_BASE_URL}/closet/signed-url`, payload, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  s3Upload(url, file) {
    return axios.put(url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
  }

  handlePromises(promiseArray) {
    return Promise.allSettled(promiseArray);
  }
}

export default FileUploadService;
