import React, { useEffect, useState } from "react";
import Account from "../components/Account";
import { Box, Tab, Tabs } from "@mui/material";
import ClosetComponent from "../components/ClosetComponent";
import HomeStyles from "../styles/home.css";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { packageApiCall } from "../utils/renderFunctions";
import OutfitBoards from "../components/OutfitBoards";

export const links = () => [{ rel: "stylesheet", href: HomeStyles }];

const Home = () => {
  const location = useLocation();
  const [packageName, setPackageName] = useState("");
  const [value, setValue] = React.useState("1");
  const [customerData, setCustomerData] = useState({
    first_name: "",
    last_name: "",
    id: "",
    email: "",
    phone: "",
    height: "",
    weight: "",
    body_shape: "",
    preferred_style: "",
    chat_sessions_available: 0,
    video_sessions_available: 0,
    total_chat_sessions: 0,
    total_video_sessions: 0,
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCustomerData = async (data) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/customer/${data?.id}`
      );
      setCustomerData({
        ...customerData,
        first_name: data.first_name ?? customerData?.first_name,
        last_name: data.last_name ?? customerData?.last_name,
        id: response?.data?.customer?.id ?? data?.id,
        email: response?.data?.customer?.email ?? data?.email,
        phone: response?.data?.customer?.phone ?? data?.phone,
        height: response?.data?.customer?.height ?? customerData?.height,
        weight: response?.data?.customer?.weight ?? customerData?.weight,
        body_shape:
          response?.data?.customer?.body_shape ?? customerData?.body_shape,
        preferred_style:
          response?.data?.customer?.preferred_style ??
          customerData?.preferred_style,
        chat_sessions_available:
          response?.data?.customer?.chat_sessions_available ??
          customerData?.chat_sessions_available,
        video_sessions_available:
          response?.data?.customer?.video_sessions_available ??
          customerData?.video_sessions_available,
        total_chat_sessions:
          response?.data?.customer?.total_chat_sessions ??
          customerData?.total_chat_sessions,
        total_video_sessions:
          response?.data?.customer?.total_video_sessions ??
          customerData?.total_video_sessions,
      });

      const packageResponse = await packageApiCall(
        response?.data?.customer?.id ?? data?.id
      );

      if (packageResponse?.data?.message === "You don't have plan") {
        setPackageName("You don't have plan");
      } else {
        setPackageName(packageResponse?.data?.package_name);
      }
    } catch (err) {
      console.log("error:", err);
    }
  };

  const handleUserShopifyData = () => {
    var parameters = {},
      hash;
    var q = location.search.split("?")[1];
    if (q !== undefined) {
      q = q.split("&");
      for (var i = 0; i < q.length; i++) {
        hash = q[i].split("=");
        parameters[hash[0]] = hash[1];
      }
    }

    console.log("parameters=", parameters);

    const userValues = {
      id: parameters?.id ?? customerData?.id,
      email: parameters?.email ?? customerData?.email,
      phone: parameters?.phone ?? customerData?.phone,
      height: customerData?.height ?? "",
      weight: customerData?.weight ?? "",
      first_name: parameters?.first_name ?? customerData?.first_name,
      last_name: parameters?.last_name ?? customerData?.last_name,
      preferred_style: customerData?.preferred_style ?? "",
      body_shape: customerData?.body_shape ?? "",
      chat_sessions_available: customerData?.chat_sessions_available ?? 0,
      video_sessions_available: customerData?.video_sessions_available ?? 0,
      total_chat_sessions: customerData?.total_chat_sessions ?? 0,
      total_video_sessions: customerData?.total_video_sessions ?? 0,
    };

    setCustomerData(userValues);
    handleCustomerData(userValues);
  };

  useEffect(() => {
    handleUserShopifyData();
  }, []);

  return (
    <div className="home-tabs-design">
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          centered
        >
          <Tab label="Account" value="1" />
          {packageName === "You don't have plan" ? (
            <Tooltip title="we want YOU to be a part of this amazing community we are building! Become a member to unlock the closet feature.">
              <div>
                <Tab label="Closet" value="2" disabled />
              </div>
            </Tooltip>
          ) : (
            <Tab label="Closet" value="2" />
          )}
          <Tab label="Outfit Boards" value="3" />
        </Tabs>
        <Box>
          {value === "1" ? (
            <Account
              customerData={customerData}
              setCustomerData={setCustomerData}
              packageName={packageName}
              setPackageName={setPackageName}
            />
          ) : value === "2" ? (
            <ClosetComponent
              customerData={customerData}
              setCustomerData={setCustomerData}
            />
          ) : (
            <OutfitBoards />
          )}
        </Box>
      </Box>
    </div>
  );
};

export default Home;
