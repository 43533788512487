import "../styles/outfitBoards.css";
const OutfitBoards = () => {
  return (
    <div className="outfitBoards">
      <div className="outfitBoards__card">
        <h3>Coming soon:</h3>
        <p>
          Your personalized stylist-curated shoppable outfit boards, from your
          one-time purchases, will be displayed here for your convenience!
        </p>
      </div>
    </div>
  );
};

export default OutfitBoards;
