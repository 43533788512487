import React, { useState } from "react";
import { Image } from "@shopify/polaris";

import typeformStyles from "../styles/typeform.css";
import { arrowRight, tick } from "../assets";
import { EventOptions } from "../utils/constants";

export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const EventCard = ({ handleCardDisplay, setTypeFormData, typeFormData, setValidDetails }) => {
  const [errorFlag, setErrorFlag] = useState(false);

  const handleOptionChange = (option) => {
    setErrorFlag(false);
    setValidDetails(false);
    if (option) {
      setTypeFormData({ ...typeFormData, event: option });
    } else {
      setTypeFormData({ ...typeFormData, event: null });
    }
  };

  const isOptionSelected = (option) => typeFormData.event === option;

  return (
    <div className="question-box">
      <div className="question-cards">
        <div className="question-no">
          <div className="question-arrow">
            {" "}
            1 <Image source={arrowRight} alt="arrow" />
          </div>
        </div>
        <div className="question-info">
          <h4>
            Select the event or occasion you are dressing for?
            {errorFlag && <small>This question is required.*</small>}
          </h4>
          <p>Decisions are hard. Just choose the option that feels right!</p>
          <div className="question-input-checkbox">
            {EventOptions.map((el, index) => (
              <div className="checkbox-items" key={index}>
                <input
                  type="radio"
                  name="a"
                  checked={isOptionSelected(el.title)}
                  onChange={() => handleOptionChange(el.title)}
                  value={typeFormData.event}
                />
                <div className="checkbox-group">
                  <div className="checkbox-image">
                    <Image source={el.icon} alt="icon" />
                  </div>
                  <div className="checkbox-title">
                    <span>{el.label}</span>
                    <p>{el.title}</p>
                  </div>
                  <div className="input-checked">
                    <Image source={tick} alt="tick" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div>
            {" "}
            <button
              onClick={() => {
                if (typeFormData.event === null || typeFormData.event === undefined) {
                  setErrorFlag(true);
                } else handleCardDisplay(2);
              }}
              className="question-button"
              type="submit"
            >
              OK <Image source={tick} alt="tick" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
