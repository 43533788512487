import axios from "axios";
import { callImage } from "../assets";
import { chatApiCall } from "../utils/renderFunctions";
import { useEffect } from "react";

const ThankYouChat=({displayThankYou,setDisplayThankYou,setOpenQR,setOpenChat,customerData,setCustomerData,typeFormData})=>{

    const handleHomeBtn=()=>{

        setDisplayThankYou({...displayThankYou, forChat:false})
        setOpenQR(false);
        setOpenChat(false);
    }

    const handleApiCall = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/customer/${customerData.id}`
          );
          setCustomerData({
            ...customerData,
            chat_sessions_available:
              response?.data?.customer?.chat_sessions_available,
          });
        } catch (error) {
          console.log("error=", error);
        }
      };
    
      const handleChatApiCall = async () => {
        await chatApiCall("chat", typeFormData);
        await handleApiCall();
      };
    
      useEffect(() => {
        handleChatApiCall();
      }, []);

    return(
        <div className="thankyou-msg">
        <div className="thankyou-msg-img">
        <img src={callImage} alt="call" />
        </div>
        <div className="thankyou-msg-info"> 
        <h1>Thank you</h1>
        <p>Please check your phone for a text message from one of our stylists. </p>
        <p>
             We can't wait to chat with you soon!
        </p>
        <button  className="form-button-style" onClick={handleHomeBtn}>Home</button>
        </div>
        </div>
    )
}

export default ThankYouChat;