import { videoImage } from "../assets";

const ThankYouVideo=({displayThankYou,setDisplayThankYou,setOpenVideo,setOpenBookVideoChat})=>{

    const handleHomeBtn=()=>{

        setDisplayThankYou({...displayThankYou, forVideo:false})
        setOpenVideo(false);
        setOpenBookVideoChat(false);
    }

    return(
        <div className="thankyou-msg">
        <div className="thankyou-msg-img">
        <img src={videoImage} alt="call" />
        </div>
        <div className="thankyou-msg-info"> 
        <h1>Thank you</h1>
        <p>We can't wait to style you!</p>
        <button className="form-button-style"  onClick={handleHomeBtn}>Home</button>
        </div>
        </div>
    )
}

export default ThankYouVideo;