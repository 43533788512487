import CityCard from "../cards/CityCard";
import EmailCard from "../cards/EmailCard";
import EventCard from "../cards/EventCard";
import FeelCard from "../cards/FeelCard";
import HomeCard from "../cards/HomeCard";
import NameCard from "../cards/NameCard";
import OutfitCard from "../cards/OutfitCard";
import PhoneCard from "../cards/PhoneCard";
import DateCard from "../cards/DateCard";
import axios from "axios";

export const renderTypeFormApp = (
  handleCardDisplay,
  typeFormData,
  setTypeFormData,
  btn,
  handleTypeFormSubmit,
  errorPhone,
  setErrorPhone,
  validPhoneFlag,
  setValidPhoneFlag,
  validDetails,
  setValidDetails
) => {
  switch (btn) {
    case 0: {
      return <HomeCard handleCardDisplay={handleCardDisplay} />;
    }
    case 1: {
      return (
        <EventCard
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
          handleCardDisplay={handleCardDisplay}
          setValidDetails={setValidDetails}
        />
      );
    }
    case 2: {
      return (
        <FeelCard
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
          handleCardDisplay={handleCardDisplay}
          setValidDetails={setValidDetails}
        />
      );
    }
    case 3: {
      return (
        <CityCard
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
          handleCardDisplay={handleCardDisplay}
          setValidDetails={setValidDetails}
        />
      );
    }
    case 4: {
      return (
        <DateCard
          handleCardDisplay={handleCardDisplay}
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
        />
      );
    }
    case 5: {
      return (
        <OutfitCard
          handleCardDisplay={handleCardDisplay}
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
        />
      );
    }
    // case 6: {
    //   return (
    //     <OptionalOutfitCard
    //       handleCardDisplay={handleCardDisplay}
    //       typeFormData={typeFormData}
    //       setTypeFormData={setTypeFormData}
    //     />
    //   );
    // }
    case 6: {
      return (
        <NameCard
          handleCardDisplay={handleCardDisplay}
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
          setValidDetails={setValidDetails}
        />
      );
    }
    case 7: {
      return (
        <EmailCard
          handleCardDisplay={handleCardDisplay}
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
          setValidDetails={setValidDetails}
        />
      );
    }
    case 8: {
      return (
        <PhoneCard
          typeFormData={typeFormData}
          setTypeFormData={setTypeFormData}
          handleSubmit={handleTypeFormSubmit}
          errorPhone={errorPhone}
          setErrorPhone={setErrorPhone}
          validPhoneFlag={validPhoneFlag}
          setValidPhoneFlag={setValidPhoneFlag}
          validDetails={validDetails}
          setValidDetails={setValidDetails}
        />
      );
    }
    default: {
      return <HomeCard handleCardDisplay={handleCardDisplay} />;
    }
  }
};

export const handleChatAndVideoOpen = async (
  type,
  customerData,
  setProfile,
  setOpenBookVideoChat,
  setOpenChat,
  setOpenVideo,
  setOpenQR,
) => {
  
  // eslint-disable-next-line default-case
  switch (type) {
    
    case "chat": {
      if (+(customerData.chat_sessions_available) === 0) {
        
        return;
      }
      setOpenChat(true);
      setOpenBookVideoChat(false);
      
      break;
    }
    case "video": {
      if (+(customerData.video_sessions_available) === 0) {
        return;
      }
  
      setOpenBookVideoChat(true);
      setOpenChat(false);
      
      break;
    }
  }

};

export const navigateToPackages = () => {
  const shopifyPageUrl =
    "https://lookingglasslifestyle.com/collections/style-subscriptions";

  window.top.location.href = shopifyPageUrl;
};

export const chatApiCall=async (type,typeFormData)=>{
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/subscription/used/${typeFormData.customerId}`,
      {
        key: type,
      }
    );
    return res;
  } catch (error) {
    console.log("error:", error);
  }
}

export const packageApiCall=async(id)=>{
  try{

    const response= await axios.get(`${process.env.REACT_APP_BASE_URL}/subscription/latest/${id}`);
    return response

  }catch(error){

  }
}