import axios from "axios";
import { useEffect, useState } from "react";
import typeformStyles from "../styles/typeform.css";
import indexStyles from "../styles/form.css";
import { useForm } from "react-hook-form";
import chatStyle from "../chat/chat.css";
import bookVideoChatStyle from "../book-video-chat/book-video-session.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ReactInputMask from "react-input-mask";
import { handleChatAndVideoOpen, navigateToPackages } from "../utils/renderFunctions";
import QuestionForm from "../components/questionForm";
import BookVideoChat from "../book-video-chat/BookVideoChat";
import ThankYouChat from "../components/thankYouChat";
import ThankYouVideo from "../components/thankYouVideo";

export const links = () => [
  { rel: "stylesheet", href: typeformStyles },
  { rel: "stylesheet", href: indexStyles },
  { rel: "stylesheet", href: chatStyle },
  { rel: "stylesheet", href: bookVideoChatStyle },
];

export default function Account({customerData,setCustomerData, packageName}) {
  const [openChat, setOpenChat] = useState(false);
  const [profile, setProfile] = useState(false);
  const [btn, setBtn] = useState(0);
  const [upDisable, setUpDisable] = useState(false);
  const [downDisable, setDownDisable] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [validPhoneFlag, setValidPhoneFlag] = useState(false);
  const [validDetails, setValidDetails] = useState(false);
  const [openBookVideoChat, setOpenBookVideoChat] = useState(false);
  const [openQR, setOpenQR] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [displayProfile, setDisplayProfile] = useState(true);

  const [displayThankYou, setDisplayThankYou] = useState({
    forChat: false,
    forVideo: false,
  });
  const [typeFormData, setTypeFormData] = useState({
    customerId: "",
    phone: "",
    event: null,
    feel: null,
    city: "",
    name: "",
    email: "",
    // optional_outfit: [],
    outfit: [],
    date: `${new Date().getMonth() + 1}/${new Date().getDate()}/${new Date().getFullYear()}`,
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const handleCustomerData = async () => {

      setTypeFormData({
        ...typeFormData,
        name: `${customerData?.first_name} ${customerData?.last_name}`,
        email: customerData?.email,
        customerId: customerData?.id,
      });
  };

  const handleSubmitForm = async (values) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}/customer`, {
        ...customerData,
        id: customerData?.id,
        email: values.email,
        phone: values.phone,
        height: values.height,
        weight: values.weight,
        body_shape: values.body_shape,
        preferred_style: values.preferred_style,
      });
      setCustomerData({
        ...customerData,
        first_name: res?.data?.first_name,
        last_name: res?.data?.last_name,
        id: res?.data?.id,
        email: res?.data?.email,
        phone: res?.data?.phone,
        height: res?.data?.height,
        weight: res?.data?.weight,
        body_shape: res?.data?.body_shape,
        preferred_style: res?.data?.preferred_style,
        chat_sessions_available: res?.data?.chat_sessions_available,
        video_sessions_available: res?.data?.video_sessions_available,
        total_chat_sessions: res?.data?.total_chat_sessions,
        total_video_sessions: res?.data?.total_video_sessions,
      });

      setTypeFormData({ ...typeFormData, customerId: res?.data?.id });
      setProfile(res?.data?.profile);
      setDisplayProfile(false);
    } catch (error) {
      console.log("error:", error);
    }
  };

  const renderProfileForm = () => {
    return (
      <div className="form-space">
        <div className="text-align-center">
          <h3 className="mb-1">Profile Information:</h3>
          <p className="mt-0">Give your stylists more helpful info</p>
        </div>
        <form onSubmit={handleSubmit(handleSubmitForm)} className="form">
          <div className="formInner">
            <div className="formInputCol">
              <input
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                placeholder="Email Address"
                disabled
              />
              {errors?.email?.type === "required" && <p>This field is required</p>}
              {errors?.email?.type === "pattern" && <p>Invalid Email</p>}
            </div>
            <div className="formInputCol">
              <ReactInputMask
                mask="(999) 999-9999"
                {...register("phone", {
                  required: true,
                })}
                placeholder="(201) 555-0123"
              />
              {errors?.phone?.type === "required" && <p>This field is required</p>}
            </div>
          </div>
          <div className="formInner">
            <div className="formInputCol">
              <input
                {...register("height", {
                  required: true,
                  pattern: /^[0-9]+$/,
                })}
                type="number"
                placeholder="Height"
              />
              {errors?.height?.type === "required" && <p>This field is required</p>}
              {errors?.height?.type === "pattern" && <p>Invalid Height</p>}
            </div>
            <div className="formInputCol">
              <input
                {...register("weight", {
                  required: true,
                  pattern: /^[0-9]+$/,
                })}
                type="number"
                placeholder="Weight"
              />
              {errors?.weight?.type === "required" && <p>This field is required</p>}
              {errors?.weight?.type === "pattern" && <p>Invalid Weight</p>}
            </div>
          </div>
          <div className="formInner">
            <div className="formInputCol">
              <FormControl fullWidth>
                {customerData.body_shape ? (
                  <InputLabel>{customerData.body_shape}</InputLabel>
                ) : (
                  <InputLabel>Body Shape</InputLabel>
                )}
                <Select label="Body Shape" {...register("body_shape", { required: true })}>
                  <MenuItem value="Stick">Stick</MenuItem>
                  <MenuItem value="Hourglass">Hourglass</MenuItem>
                  <MenuItem value="Apple">Apple</MenuItem>
                  <MenuItem value="Pear">Pear</MenuItem>
                </Select>
                {errors?.body_shape?.type === "required" && <p>Body Shape not selected</p>}
              </FormControl>
            </div>
            <div className="formInputCol">
              <FormControl fullWidth>
                {customerData.preferred_style ? (
                  <InputLabel>{customerData.preferred_style}</InputLabel>
                ) : (
                  <InputLabel>Preferred Style</InputLabel>
                )}
                <Select label="Preferred Style" {...register("preferred_style", { required: true })}>
                  <MenuItem value="Fit Right In">Fit Right In</MenuItem>
                  <MenuItem value="Smoking Hot">Smoking Hot</MenuItem>
                  <MenuItem value="Polished Business">Polished Business</MenuItem>
                  <MenuItem value="Stylish & Trendy">Stylish & Trendy</MenuItem>
                  <MenuItem value="Mike's Dad Style">Mike's Dad Style</MenuItem>
                </Select>
                {errors?.preferred_style?.type === "required" && <p>Preferred Style not selected</p>}
              </FormControl>
            </div>
          </div>
          <div className="text-align-center">
            <button className="form-button-style" type="submit">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    );
  };

  useEffect(() => {
    reset({
      ...customerData,
    });
  }, [customerData]);

  useEffect(() => {
    if(customerData?.id){
      console.log("customer data",customerData);
      handleCustomerData();
    }
  }, [customerData?.id]);

  return (
    <div className="index">
      <div className="content">
        <h2>Hi there, {customerData ? customerData.first_name : ""}</h2>

        <div className="membership-section">
          {!openChat &&
            !openBookVideoChat &&
            !openQR &&
            !openVideo &&
            !displayThankYou.chat &&
            !displayThankYou.forVideo && (
              <div className="text-align-center membership-text">
                <h2>
                  Membership Information: <span>{`${packageName}`}</span>
                </h2>
              </div>
            )}
          {(openChat || openBookVideoChat) && (
            <QuestionForm
              typeFormData={typeFormData}
              setTypeFormData={setTypeFormData}
              btn={btn}
              setUpDisable={setUpDisable}
              setDownDisable={setDownDisable}
              setBtn={setBtn}
              errorPhone={errorPhone}
              setErrorPhone={setErrorPhone}
              validPhoneFlag={validPhoneFlag}
              setValidPhoneFlag={setValidPhoneFlag}
              openChat={openChat}
              OpenBookVideoChat={openBookVideoChat}
              setOpenVideo={setOpenVideo}
              setOpenChat={setOpenChat}
              setOpenBookVideoChat={setOpenBookVideoChat}
              setDisplayThankYou={setDisplayThankYou}
              displayThankYou={displayThankYou}
              setOpenQR={setOpenQR}
              validDetails={validDetails}
              setValidDetails={setValidDetails}
            />
          )}
          {displayThankYou.forChat && (
            <ThankYouChat
              displayThankYou={displayThankYou}
              setDisplayThankYou={setDisplayThankYou}
              setOpenQR={setOpenQR}
              setOpenChat={setOpenChat}
              customerData={customerData}
              setCustomerData={setCustomerData}
              typeFormData={typeFormData}
              setTypeFormData={setTypeFormData}
            />
          )}
          {displayThankYou.forVideo && (
            <ThankYouVideo
              displayThankYou={displayThankYou}
              setDisplayThankYou={setDisplayThankYou}
              setOpenVideo={setOpenVideo}
              setOpenBookVideoChat={setOpenBookVideoChat}
            />
          )}
          {/* {openQR && (
            <Chat
              email={customerData.email}
              name={customerData.name}
              customerData={customerData}
              setCustomerData={setCustomerData}
              handleCustomerData={handleCustomerData}
              typeFormData={typeFormData}
            ></Chat>
          )} */}
          {openVideo && (
            <BookVideoChat
              setOpenBookVideoChat={setOpenBookVideoChat}
              setOpenVideo={setOpenVideo}
              typeFormData={typeFormData}
              setCustomerData={setCustomerData}
              customerData={customerData}
              setDisplayThankYou={setDisplayThankYou}
              displayThankYou={displayThankYou}
              setOpenQR={setOpenQR}
              setOpenChat={setOpenChat}
            ></BookVideoChat>
          )}
          {!openChat &&
            !openBookVideoChat &&
            !openQR &&
            !openVideo &&
            !displayThankYou.chat &&
            !displayThankYou.forVideo && (
              <>
                <div className="button-wrap-member">
                  <div className="chat-member-section">
                    <h3>
                      Chat Sessions Available:{" "}
                      {customerData.total_chat_sessions > 1000
                        ? `Unlimited`
                        : `${customerData.chat_sessions_available}/${customerData.total_chat_sessions}`}
                    </h3>
                    <button
                      className="form-button-style"
                      onClick={() => {
                        setBtn(0);
                        setOpenVideo(false);
                        setOpenQR(false);
                        handleChatAndVideoOpen(
                          "chat",
                          customerData,
                          setProfile,
                          setOpenBookVideoChat,
                          setOpenChat,
                          setOpenVideo,
                          setOpenQR
                        );
                      }}
                      disabled={openChat}
                    >
                      Chat Now
                    </button>
                  </div>
                  <div className="video-member-section">
                    <h3>
                      {" "}
                      Audio/Video Sessions Available:{" "}
                      {`${customerData.video_sessions_available}/${customerData.total_video_sessions}`}
                    </h3>
                    <button
                      className="form-button-style"
                      onClick={() => {
                        setBtn(0);
                        setOpenQR(false);
                        setOpenVideo(false);
                        handleChatAndVideoOpen(
                          "video",
                          customerData,
                          setProfile,
                          setOpenBookVideoChat,
                          setOpenChat,
                          setOpenQR,
                          setOpenVideo
                        );
                      }}
                      disabled={openBookVideoChat}
                    >
                      Book Video Chat
                    </button>
                  </div>
                </div>

                <div className="or-style"></div>

                {displayProfile ? (
                  <>
                    <div>{renderProfileForm()}</div>
                  </>
                ) : (
                  <div className="error-box success-message profile-message">Profile Information Saved</div>
                )}

                <div className="text-align-center">
                  <h3>
                    {" "}
                    *Please note that the 3 monthly subscription credits expire after 1 month. You can add more credits
                    above.
                  </h3>
                  <h2>Want to upgrade? Add another session below</h2>
                </div>
                <div className="button-wrap">
                  <button className="form-button-style" onClick={navigateToPackages}>
                    Add More Chat Sessions
                  </button>
                  <button className="form-button-style" onClick={navigateToPackages}>
                    Add More Video Chat Sessions
                  </button>
                </div>
              </>
            )}
        </div>
      </div>
    </div>
  );
}
