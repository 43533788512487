import { CircularProgress, Dialog } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FileUpload from "./FileUpload";
import {
  FILE_UPLOAD_SIZE,
  UPLOAD_FILE_TYPE,
  // customerId,
  defaultFilterValue,
  defaultValue,
  // email,
} from "../utils/constants";
import FileUploadService from "../utils/fileUploadService";
import closetStyles from "../styles/closet.css";
import axios from "axios";
import ClosetHeader from "./ClosetHeader";
import { plus, cross } from "../assets";
import CardContainer from "./CardContainer";
import { checkUploadFileSize, createS3Path, getClosets } from "../utils/utils";
import ConfirmationModal from "./ConfirmationModal";

export const links = () => [{ rel: "stylesheet", href: closetStyles }];

const ClosetComponent = ({customerData,setCustomerData}) => {
  const [submitValue, setSubmitValue] = useState(defaultValue);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [videoFiles, setVideoFiles] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [categoriesAndBrand, setCategoriesAndBrand] = useState();
  const [filterValue, setFilterValue] = useState(defaultFilterValue);
  const imageRef = useRef(null);
  const videoRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [closetData, setClosetData] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const closetId = useRef("");

  const getAllClosets = async () => {
    setLoading(true);
    try {
      const res = await getClosets(customerId, filterValue);
      setClosetData(res?.data);
    } catch (error) {
      setClosetData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = (event, reason, type, id) => {
    if (type === "delete" && id) {
      closetId.current = id;
      setShowConfirmModal(true);
    } else {
      if (reason && reason === "backdropClick") return;
      setShowModal(!showModal);
      setSubmitValue(defaultValue);
      setImageFiles([]);
      setVideoFiles([]);
      clearInputFile();
    }
  };

  const handleValueSet = (event, itemName = "", text) => {
    const { name, value } = event.target;
    if (itemName) {
      setSubmitValue({ ...submitValue, [itemName]: text });
    } else {
      setSubmitValue({ ...submitValue, [name]: value });
    }
  };

  const handleValueFilter = (event) => {
    const { name, value } = event?.target;
    setFilterValue({ ...filterValue, [name]: value });
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
  };

  const clearInputFile = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.value = "";
    }
    if (videoRef && videoRef.current) {
      videoRef.current.value = "";
    }
  };

  const handleFileUpload = async (files, type) => {
    try {
      const fileUpload = new FileUploadService();
      const path = createS3Path({
        email: email,
        type,
        category: submitValue.category,
        season: submitValue.season,
        brand: submitValue.brand,
        color: submitValue.color,
      });
      return await fileUpload.uploadFile({ files, path });
    } catch (error) {
      console.log(error);
      return [];
    }
  };

  const handleFileDrop = (event, type) => {
    event.preventDefault();
    const { files } = event.dataTransfer;
    updateFiles(files, type);
  };

  const handleOnChange = (event, type) => {
    const { files } = event.target;
    updateFiles(files, type);
  };

  const updateFiles = (files, type) => {
    let checkedFiles = [];
    switch (type) {
      case UPLOAD_FILE_TYPE.VIDEO:
        checkedFiles = checkUploadFileSize(files, FILE_UPLOAD_SIZE.VIDEO_SIZE);
        setVideoFiles([...videoFiles, ...checkedFiles]);
        break;
      default:
        checkedFiles = checkUploadFileSize(files, FILE_UPLOAD_SIZE.IMAGE_SIZE);
        setImageFiles([...imageFiles, ...checkedFiles]);
        break;
    }
    clearInputFile();
  };

  const getCategories = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/closet/categories?userId=${customerId}`);
      setCategoriesAndBrand(res?.data);
    } catch (error) {
      console.log(error, "while fetching categories");
    }
  };

  const handleSubmitForm = async () => {
    setButtonDisabled(true);
    setSubmitLoading(true);
    try {
      const vFiles = await handleFileUpload(videoFiles, UPLOAD_FILE_TYPE.VIDEO);
      const iFiles = await handleFileUpload(imageFiles, UPLOAD_FILE_TYPE.IMAGE);
      const payload = {
        ...submitValue,
        email: email,
        user_id: customerId,
        video: vFiles,
        image: iFiles,
      };
      await axios.post(`${process.env.REACT_APP_BASE_URL}/closet`, payload);
      if (submitValue.brand) {
        categoriesAndBrand.brands.push(submitValue.brand);
        setCategoriesAndBrand({ ...categoriesAndBrand });
      }
      handleClose();
      getAllClosets();
    } catch (error) {
      console.log(error, "while adding closet");
    } finally {
      setButtonDisabled(false);
      setSubmitLoading(false);
    }
  };

  const handleRemoveFile = (index, type) => {
    switch (type) {
      case UPLOAD_FILE_TYPE.VIDEO:
        videoFiles?.splice(index, 1);
        setVideoFiles([...videoFiles]);
        break;

      default:
        imageFiles?.splice(index, 1);
        setImageFiles([...imageFiles]);
        break;
    }
  };

  const handleDeleteCloset = async () => {
    try {
      await axios.delete(`${process.env.REACT_APP_BASE_URL}/closet/${customerId}/${closetId.current}`);
      setShowConfirmModal(false);
      getAllClosets();
    } catch (error) {}
  };

  const handleUserShopifyData = () => {
    setEmail(customerData?.email);
    setCustomerId(customerData?.id);
  };

  useEffect(() => {
    handleUserShopifyData();
  }, []);

  useEffect(() => {
    if (customerId) {
      getAllClosets();
    }
  }, [filterValue, customerId]);

  useEffect(() => {
    if(customerId){
      getCategories();
    }
   
  }, [customerId]);

  useEffect(() => {
    if ((videoFiles.length || imageFiles.length) && submitValue.category) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [videoFiles, imageFiles, submitValue.category]);

  return (
    <>
      <div div className="closet-wrapper">
        <ClosetHeader
          categoriesAndBrand={categoriesAndBrand}
          value={filterValue}
          handleValueFilter={handleValueFilter}
          setFilterValue={setFilterValue}
        />

        {loading ? (
          <div className="closet-card-loader">
            <CircularProgress size={30} />
          </div>
        ) : (
          <CardContainer data={closetData} handleClose={handleClose} filterValue={filterValue} />
        )}
        <Dialog open={showModal} onClose={handleClose} className="closet-dialog">
          <button className="closet-close" onClick={handleClose}>
            <img src={cross} alt="close" />
          </button>
          <h2>Add items</h2>
          <FileUpload
            value={submitValue}
            handleValueSet={handleValueSet}
            handleOnChange={handleOnChange}
            handleSubmitForm={handleSubmitForm}
            buttonDisabled={buttonDisabled}
            categories={categoriesAndBrand?.category ?? []}
            videoFiles={videoFiles}
            imageFiles={imageFiles}
            handleRemoveFile={handleRemoveFile}
            handleFileDragOver={handleFileDragOver}
            handleFileDrop={handleFileDrop}
            imageRef={imageRef}
            videoRef={videoRef}
            loading={submitLoading}
          />
        </Dialog>
        <ConfirmationModal
          open={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          handleDeleteCloset={handleDeleteCloset}
        />
        <div className="closet-add">
          <button className="closet-add-button" onClick={handleClose}>
            <img src={plus} alt="plus" />
          </button>
        </div>
      </div>
    </>
  );
};

export default ClosetComponent;
