import { InlineWidget } from "react-calendly";
import { chatApiCall } from "../utils/renderFunctions";
import { useEffect } from "react";
import axios from "axios";
const BookVideoChat = ({
  setOpenBookVideoChat,
  setOpenVideo,
  typeFormData,
  setCustomerData,
  customerData,
  setDisplayThankYou,
  displayThankYou,
  setOpenQR,
  setOpenChat
}) => {
  const handleClose = () => {
    chatApiCall("video", typeFormData);
    setOpenVideo(false);
    setOpenBookVideoChat(false);
    setOpenQR(false);
    setOpenChat(false);
    setDisplayThankYou({...displayThankYou, forVideo:true})
  };

  const handleApiCall = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/customer/${customerData.id}`
      );
      setCustomerData({
        ...customerData,
        video_sessions_available:
          response?.data?.customer?.video_sessions_available,
      });
    } catch (error) {
      console.log("error=", error);
    }
  };

  const handleVideoApiCall = async () => {
    await chatApiCall("video", typeFormData);
    await handleApiCall();
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    handleVideoApiCall();
  }, []);
  return (
    <>
      <div className="book-video-session">
        <h2>Book Video Session</h2>
        <div className="button-wrap">
          <button className="form-button-style" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
      <div>
        <InlineWidget
          url="https://calendly.com/30-min-video-chat-with-a-lookingglass-stylist"
          styles={{ height: "700px" }}
        />
      </div>
    </>
  );
};
export default BookVideoChat;
