import React, { useState } from "react";
import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { arrowRight, tick, errorIcon } from "../assets";
import { FeelOptions } from "../utils/constants";

export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const FeelCard = ({ setTypeFormData, typeFormData, handleCardDisplay, setValidDetails }) => {
  const [errorFlag, setErrorFlag] = useState(false);

  const handleOptionChange = (option) => {
    setErrorFlag(false);
    setValidDetails(false);
    if (option) {
      setTypeFormData({ ...typeFormData, feel: option });
    } else {
      setTypeFormData({ ...typeFormData, feel: null });
    }
  };

  const isOptionSelected = (option) => typeFormData.feel === option;

  return (
    <div className="question-box">
      <div className="question-cards">
        <div className="question-no">
          <div className="question-arrow">
            {" "}
            2 <Image source={arrowRight} alt="arrow" />
          </div>
        </div>
        <div className="question-info">
          <h4>
            How do you want to feel when you walk into a room?
            {errorFlag && <small>This question is required.*</small>}
          </h4>
          <p>There are no wrong answers. Just go with your instincts! </p>
          <div className="question-input-checkbox">
            {FeelOptions.map((el, index) => (
              <div className="checkbox-items" key={index}>
                <input
                  type="radio"
                  name="a"
                  checked={isOptionSelected(el.title)}
                  onChange={() => handleOptionChange(el.title)}
                  value={typeFormData.feel}
                />
                <div className="checkbox-group">
                  <div className="checkbox-image">
                    <Image source={el.icon} alt="icon" />
                  </div>
                  <div className="checkbox-title">
                    <span>{el.label}</span>
                    <p>{el.title}</p>
                  </div>
                  <div className="input-checked">
                    <Image source={tick} alt="tick" />
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* {errorFlag && (
            <div className="error-box">
              <Image source={errorIcon} alt="error" /> <strong>Oops!</strong>{" "}
              Please make a selection
            </div>
          )} */}
          <div>
            <button
              onClick={() => {
                if (typeFormData.feel === null || typeFormData.feel === undefined) {
                  setErrorFlag(true);
                } else handleCardDisplay(3);
              }}
              className="question-button"
              type="submit"
            >
              OK <Image source={tick} alt="tick" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeelCard;
