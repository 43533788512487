import { Image } from "@shopify/polaris";
import typeformStyles from "../styles/typeform.css";
import { arrowRight, uploadIcon, tick, errorIcon, loader, trash } from "../assets";
import { useEffect, useState } from "react";
import axios from "axios";

export const links = () => [{ rel: "stylesheet", href: typeformStyles }];

const OutfitCard = ({ handleCardDisplay, typeFormData, setTypeFormData }) => {
  const [errorFlagFile, setErrorFlagFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filesUploadedFlag, setFilesUploadedFlag] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileInvalid, setFileInvalid] = useState(false);
  const regex = /\/([^\/?#]+)[^\/]*$/;

  const uploadFileToS3 = async (selectedFiles) => {
    const uploadedFiles = [...typeFormData?.outfit];
    if (files.length + selectedFiles.length > 5) {
      setErrorFlagFile(true);
      setFilesUploadedFlag(false);
      return;
    } else {
      setFilesUploadedFlag(false);
      setErrorFlagFile(false);

      for (const file of selectedFiles) {
        const payload = {
          key: file.name,
          method: "put",
          content: "abc",
        };

        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/signed-url`, payload);
          await axios.put(response.data.result.url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
          const url = `${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/${file.name}`;          

          uploadedFiles.push(url);
        } catch (error) {
          console.log("error=", error);
        }
      }
      setTypeFormData({ ...typeFormData, outfit: [...uploadedFiles] });
      setLoading(false);
      setFilesUploadedFlag(true);
      return;
    }
  };

  const handleFileUpload = (selectedFiles, invalidFlag) => {
    const newFiles = [...files, ...selectedFiles];

    if (invalidFlag) {
      return;
    }

    if (newFiles?.length > 5 || files?.length > 5 || typeFormData.outfit?.length + selectedFiles?.length > 5) {
      setErrorFlagFile(true);
      setFiles([]);
      return;
    }

    setLoading(true);

    uploadFileToS3(selectedFiles);
    setFiles(newFiles);
  };

  const handleRemoveImage = (index) => {
    files?.splice(index, 1);
    typeFormData.outfit?.splice(index, 1);
    setFiles([...files]);
    setTypeFormData({ ...typeFormData, outfit: [...typeFormData.outfit] });
    setErrorFlagFile(false);
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    setErrorFlagFile(false);
    setLoading(false);
    setFileInvalid(false);
    let invalidFlag = false;

    const selectedFiles = Array.from(event.dataTransfer.files);

    for (const file of selectedFiles) {
      if (!["image/png", "image/jpeg", "image/jpg", "image/bmp"].includes(file.type)) {
        setFileInvalid(true);
        invalidFlag = true;
      }
    }

    handleFileUpload(selectedFiles, invalidFlag);
  };

  const handleFileChange = (event) => {
    setErrorFlagFile(false);
    setLoading(false);
    setFileInvalid(false);
    let invalidFlag = false;

    const selectedFiles = Array.from(event.target.files);

    for (const file of selectedFiles) {
      if (!["image/png", "image/jpeg", "image/jpg", "image/bmp"].includes(file.type)) {
        setFileInvalid(true);
        invalidFlag = true;
      }
    }

    handleFileUpload(selectedFiles, invalidFlag);
  };

  const handleFileDragOver = (event) => {
    event.preventDefault();
  };

  const handleButton = () => {
    if (!loading) {
      setErrorFlagFile(false);
      handleCardDisplay(6);
      return;
    }
  };

  const handleDataDisplay = () => {
    setTypeFormData({ ...typeFormData, outfit: [...typeFormData.outfit] });
    setFilesUploadedFlag(true);
  };

  useEffect(() => {
    if (typeFormData?.outfit?.length > 0) {
      handleDataDisplay();
    }
  }, []);

  return (
    <div className="question-box upload-question">
      <div className="question-cards">
        <div className="question-no">
          <div className="question-arrow">
            {" "}
            5 <Image source={arrowRight} alt="arrow" />
          </div>
        </div>
        <div className="question-info">
          <h4>Do you have an outfit that you're already considering wearing?</h4>
          <p>
            Upload an optional image of an outfit that you're considering wearing. This will help our stylist's better
            understand the look that you're going for.
          </p>
          <div className="note-design">Upload upto max 5 Images</div>
          <div className="question-upload" onDrop={handleFileDrop} onDragOver={handleFileDragOver}>
            <input
              type="file"
              multiple
              id="fileInput"
              onChange={handleFileChange}
              accept="image/png , image/jpeg, image/jpg, image/bmp"
            />
            <div className="upload-info">
              {loading && (
                <p>
                  <Image source={loader} alt="loader" />
                </p>
              )}
              {!loading && (
                <>
                  <Image source={uploadIcon} alt="upload" />
                  <p>
                    <strong>Choose file</strong> or <strong>drag here</strong>
                    <small>Size limit: 10MB</small>
                  </p>
                </>
              )}
            </div>
          </div>
          {filesUploadedFlag && (
            <div className="uploaded-files">
              {typeFormData.outfit?.map((el, index) => {
                const matches = el.match(regex);

                const name = matches && matches.length > 1 ? matches[1] : "";

                return (
                  <div className="uploaded-items" key={index}>
                    <span className="uploaded-items-name"> {name}</span>
                    <button className="uploaded-delete" onClick={() => handleRemoveImage(index)}>
                      <img src={trash} alt="trash" />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {errorFlagFile && (
            <div className="error-box">
              <Image source={errorIcon} alt="error" /> <strong>Oops!</strong> Uploaded files cannot be more than 5
            </div>
          )}

          {fileInvalid && <div className="error-box">Invalid File Selected!</div>}

          <div>
            <button onClick={handleButton} className="question-button" type="submit">
              OK <Image source={tick} alt="tick" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutfitCard;
