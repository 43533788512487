import React from "react";
import { UPLOAD_FILE_TYPE } from "../utils/constants";
import { trashBin } from "../assets";

const ClosetCard = ({ data, handleClose }) => {
  return (
    <div className="closet-card">
      <span className="closet-card-trash" onClick={(e) => handleClose(e, "", "delete", data?.id)}>
        <img src={trashBin} alt="trash" />
      </span>

      {data?.type === UPLOAD_FILE_TYPE.IMAGE ? (
        <img src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/${data?.url}`} alt="pic" />
      ) : (
        <video src={`${process.env.REACT_APP_AWS_CLOUDFRONT_URL}/${data?.url}`} controls />
      )}
    </div>
  );
};

export default ClosetCard;
